import { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import "@livekit/components-styles";
//import { ClientJS } from 'clientjs';
import analytics from './services/mixpanel';

import "./App.css";
import "./assets/fonts/Graphik-Semibold.ttf"
import "./assets/fonts/Graphik-Medium.ttf"
import "./assets/fonts/Graphik-Bold.ttf"

import Support from "./screens/Support";
import Call from "./screens/Call";

const App = () => {

  useEffect(() => {
    analytics.init();
  }, []);

  return (
    <Routes>
      <Route path="/:username" element={<Call />} />
      <Route path="/support" element={<Support />} />
      <Route path="/test" element={<h1 className="text-3xl font-bold underline">
      Hello world!
    </h1>} />
    </Routes>
  );
}

export default App;
