import { useEffect } from "react";

const Support = () => {

  useEffect(() => {
    window.location.href = 'https://kickback-a8c63f.webflow.io/'
  }, []);

  return <div></div>;
};

export default Support;