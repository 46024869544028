import React, { useEffect, useState } from 'react'
import { useStopwatch, useTimer } from 'react-timer-hook';
import axios from "axios"

import analytics from "../../services/mixpanel";
import MIXPANEL_KEYS from "../../services/mixpanel/keys"

import backgroundJoinRoom from "../../assets/joinRoom/background.png"
import icon from "../../assets/joinRoom/icon.png"
import { FadeLoader } from 'react-spinners';
import isDev from '../../helpers/process';

const BACKEND_URL = isDev() ? 'http://localhost:5002' : 'https://kickback-92998.web.app'

interface JoinRoomProps {
  userId: string
  username: string
  onJoin: () => void
}

const JoinRoom = ({userId, username, onJoin}: JoinRoomProps) => {
  const [isJoinging, setIsJoinging] = useState(false)
  const stopwatchOffset = new Date();
  stopwatchOffset.setSeconds(stopwatchOffset.getSeconds() + Math.floor(Math.random() * 1500) + 1)

  const {
    seconds,
    minutes,
  } = useStopwatch({ autoStart: true, offsetTimestamp: stopwatchOffset });
  const { totalSeconds, start, restart } = useTimer({expiryTimestamp: new Date(new Date().getTime() + 100000000)});

  useEffect(() => {
    if (isJoinging && totalSeconds !== 0) {
      axios.get(`${BACKEND_URL}/isOnCall?username=${username}`).then((res) => {
        if (res.data.isOnCall) {
          setIsJoinging(false)
          onJoin()
        }
      })
    }

    if (isJoinging && totalSeconds === 0) {
      setIsJoinging(false)
      onJoin()
    }
  }, [isJoinging, totalSeconds])


  useEffect(() => {
    if (userId) {
      console.log({userId})
      analytics.track(MIXPANEL_KEYS.WEB_VIEWED_PAGE)
      analytics.track(MIXPANEL_KEYS.WEB_RECEIVED_PAGEVIEW, { distinct_id: userId })
    }
  }, [userId])

  const onCreate = () => {
    analytics.track(MIXPANEL_KEYS.WEB_RECEIVED_DOWNLOAD, { distinct_id: userId })
    window.open("https://apps.apple.com/us/app/kickback-group-video-chat/id6547833850", "_blank", "noreferrer");
  }

  const onJoinCall = () => {
    axios.post(`${BACKEND_URL}/sendVoIpNotification`, { username })
    const now = new Date();
    now.setSeconds(now.getSeconds() + 7)
    restart(now)
    start()
    setIsJoinging(true)
  }

  if (isJoinging) {
    return (
      <div style={styles.container}>
        <div style={styles.joiningBackground} >
          <div style={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "center", margin: "auto", flexDirection: "column" }}>
            <div style={{ marginLeft: 10 }}>
              <FadeLoader color="white" />
            </div>
            <div style={{ fontFamily: "Graphik-Bold", fontSize: 20, color: "white", marginTop: 10 }}>Joining...</div>
            <div style={{ fontFamily: "Graphik-Medium", fontSize: 18, color: "white", marginTop: 5}}>{totalSeconds > 7 ? 7 : totalSeconds}s</div>
          </div>
        </div>
      </div>
    )
  }

  return (
      <div style={styles.container}>
        <div style={styles.buttonBackground} className='flex-col justify-between'>
          <img src={icon} alt="icon" style={{ width: 75, height: 75}}/>
          <div style={styles.buttonText} className="text-white text-center font-bold">
            {username} and others...
          </div>
          <div style={styles.username} className='text-white text-center'>{minutes}:{seconds < 10 ? `0${seconds}` : seconds}</div>
          <button
            onClick={onJoinCall}
            style={styles.button}
            className="text-white text-center font-bold w-[190px] h-[50px] rounded-full flex flex-row justify-center items-center"
          >
            {/* <img src={smallIcon} alt="icon" className="w-[36px] h-[28px]"/> */}
            Join
          </button>

          <button
            onClick={onCreate}
            style={styles.getAppButton}
            className='absolute bottom-10 text-black text-center text-xl font-bold w-[300px] h-[55px] bg-white rounded-full flex flex-row justify-center items-center'
          >
            Create your own!
          </button>
        </div>
      </div>
    )
}

const styles = {
  container: {
    height: "100vh",
    backgroundImage: `url(${backgroundJoinRoom})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    display: "flex",
    justifyContent: "center",
  },
  buttonBackground: {
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    height: "337px",
    width: "300px",
    borderRadius: 20,
    margin: "auto",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: 40,
    paddingBottom: 40,
    paddingLeft: 30,
    paddingRight: 30,
  },
  joiningBackground: {
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    height: "184px",
    width: "187px",
    borderRadius: 20,
    margin: "auto",
  },
  username: {
    fontFamily: "Graphik-Medium",
    fontSize: 16,
    opacity: 0.5,
  },
  button: {
    backgroundColor: "#02FF94",
    fontFamily: "Graphik-Bold",
    fontSize: 18,
  },
  buttonText: {
    fontFamily: "Graphik-Semibold",
    fontSize: 22,
  },
  getAppButton: {
    fontFamily: "Graphik-Bold",
    fontSize: 18,
  }
}

export default JoinRoom