import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import axios from "axios"
import {
  ControlBar,
  GridLayout,
  LiveKitRoom,
  ParticipantTile,
  RoomAudioRenderer,
  useTracks,
} from "@livekit/components-react";
import "@livekit/components-styles";
import { Track } from "livekit-client";
//import { ClientJS } from 'clientjs';

import analytics from "../../services/mixpanel";
import MIXPANEL_KEYS from "../../services/mixpanel/keys"
import isDev from "../../helpers/process"
import JoinRoom from "../JoinRoom";

//const client = new ClientJS();

const SERVER_URL = 'wss://kickback-i9xgllhr.livekit.cloud';
const BACKEND_URL = isDev() ? 'http://localhost:5002' : 'https://kickback-92998.web.app'

function uuidv4() {
  // @ts-ignore
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}

const MyVideoConference = () => {
  // `useTracks` returns all camera and screen share tracks. If a user
  // joins without a published camera track, a placeholder track is returned.
  const tracks = useTracks(
    [
      { source: Track.Source.Camera, withPlaceholder: true },
      { source: Track.Source.ScreenShare, withPlaceholder: false },
    ],
    { onlySubscribed: false },
  );
  return (
    <GridLayout tracks={tracks} style={{ height: 'calc(100vh - var(--lk-control-bar-height))' }}>
      {/* The GridLayout accepts zero or one child. The child is used
      as a template to render all passed in tracks. */}
      <ParticipantTile />
    </GridLayout>
  );
}

const Call = () => {
  const [hasJoined, setHasJoined] = useState(false)
  const [token, setToken] = useState('')
  const { username } = useParams();
  const [callUserId, setCallUserId] = useState("")
  const [userDeviceId, setUserDeviceId] = useState<string | undefined>(undefined)
  const userId = `webclient-${userDeviceId}`

  useEffect(() => {
    const deviceId = window.localStorage.getItem('deviceId')
    //console.log({deviceId})
    if (deviceId) {
      //console.log('deviceId exists')
      setUserDeviceId(String(deviceId))
    } else {
      //console.log('deviceId does not exist')
      const fing = uuidv4()
      //console.log('setting deviceId to', fing)
      window.localStorage.setItem('deviceId', String(fing))
      setUserDeviceId(fing)
    }
  }, [])

  useEffect(() => {
    if (!hasJoined) return;
  
    function beforeUnload(e: BeforeUnloadEvent) {
      e.preventDefault();
      analytics.track("participant_disconnected", { distinct_id: callUserId })
      analytics.track(MIXPANEL_KEYS.WEB_CALL_DISCONNECTED)
    }
  
    window.addEventListener('beforeunload', beforeUnload);
  
    return () => {
      window.removeEventListener('beforeunload', beforeUnload);
    };
  }, [hasJoined]);

  const getToken = () => {
    axios.get(`${BACKEND_URL}/getToken?username=${username}&userId=${userId}`)
      .then(function (response) {
        // handle success
        setToken(response.data)
      }).catch(function (error) {
        // handle error
        console.log(error);
      })
  }

  useEffect(() => {
    if(username && !token && userDeviceId) {
      getToken()
    }
  }, [username, token, userDeviceId])

  useEffect(() => {
    if(username && !callUserId) {
      axios.get(`${BACKEND_URL}/getCallUserId?username=${username}`)
        .then(function (response) {
          setCallUserId(response.data)
        })
    }
  }, [username, callUserId])

  // useEffect(() => {
  //   if (token && username) {
  //     axios.post(`${BACKEND_URL}/sendVoIpNotification`, { username })
  //   }
  // }, [token, username])

  // useEffect(() => {
  //   if (hasJoined && username) {
  //     axios.post(`${BACKEND_URL}/sendVoIpNotification`, { username })
  //   }
  // }, [username, hasJoined])

  const onJoin = () => {
    analytics.track(MIXPANEL_KEYS.WEB_TAPPED_JOIN, {
      username,
      userId,
    })
    analytics.track("participant_joined", { distinct_id: callUserId })
    setHasJoined(true)
  }

  if(!hasJoined) {
    return <JoinRoom username={username || ""} onJoin={onJoin} userId={callUserId}/>
  }

  return (
    <LiveKitRoom
      video={true}
      audio={true}
      token={token}
      serverUrl={SERVER_URL}
      // Use the default LiveKit theme for nice styles.
      data-lk-theme="default"
      style={{ height: '100vh' }}
    >
      {/* Your custom component with basic video conferencing functionality. */}
      <MyVideoConference />
      {/* The RoomAudioRenderer takes care of room-wide audio for you. */}
      <RoomAudioRenderer />
      {/* Controls for the user to start/stop audio, video, and screen
      share tracks and to leave the room. */}
      <ControlBar />
    </LiveKitRoom>
  );
}

export default Call